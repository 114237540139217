@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 10px; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffbf00; 
  /* border-radius: 3px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107ad; 
}
/* ******** */

/* animations */
.fadeIn {
  animation: fadeIn 150ms ease-in;
}
@keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}

.scaleIn {
  animation: scaleIn 300ms ease-out;
}
@keyframes scaleIn {
  0%{transform:scale(0.97);}
  50%{transform:scale(1.01);}
  100%{transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}
