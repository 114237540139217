@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 10px; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffbf00; 
  /* border-radius: 3px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107ad; 
}
/* ******** */

/* animations */
.fadeIn {
  -webkit-animation: fadeIn 150ms ease-in;
          animation: fadeIn 150ms ease-in;
}
@-webkit-keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}
@keyframes fadeIn {
  0%{opacity:0}
  100%{opacity:1;}
}

.scaleIn {
  -webkit-animation: scaleIn 300ms ease-out;
          animation: scaleIn 300ms ease-out;
}
@-webkit-keyframes scaleIn {
  0%{-webkit-transform:scale(0.97);transform:scale(0.97);}
  50%{-webkit-transform:scale(1.01);transform:scale(1.01);}
  100%{-webkit-transform:scale(1);transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}
@keyframes scaleIn {
  0%{-webkit-transform:scale(0.97);transform:scale(0.97);}
  50%{-webkit-transform:scale(1.01);transform:scale(1.01);}
  100%{-webkit-transform:scale(1);transform:scale(1);}
  
  /* 0%{
    transform:translateY(-30px);
    opacity:0;
  }
  50%{
    transform:translateY(10px);
  }
  100%{
    transform:translateY(0);
    opacity:1;
  } */
}

.Notification_Notification__1bU0X {
    background-color: #263238;
    color: #fff;
    /* border: 2px solid #fff; */
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 30px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1006;
    transition: all 500ms ease;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    margin-bottom: 10px;
    opacity: 0;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
    pointer-events: none;
    max-width: 80%;
    min-width: 200px;
    cursor: pointer;
    box-sizing: border-box;
}
.Notification_Show__2c4NL {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    pointer-events: all;
}
.Notification_Notification__1bU0X svg {
    margin-right: 5px;
}
.Notification_Notification__1bU0X.Notification_Success__1vsJw svg{
    color: #2efd50b4;
}
.Notification_Notification__1bU0X.Notification_Danger__2PYGs svg{
    color: #fd2e2e;
}
.Notification_Notification__1bU0X.Notification_Info__RA4f1 svg{
    color: #2ea0fd;
}

@media (max-width:600px) {
	.Notification_Notification__1bU0X {
        width: 100%;
        max-width: 100%;
        right: 0;
        border-radius: 0;
    }
}
.Modal_Modal__3oO7n {
    position: fixed;
    /* position: absolute; */
    left: 0;
    right: 0;
    top: -6rem;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 85vh;
    margin: auto;
    z-index: 1501;
    background-color: #fff;
    width: 90%;
    /* border: var(--border-separator); */
    box-shadow: 0px 1px 1rem 0px rgba(0,0,0,0.5);
    padding: 1rem;
    box-sizing: border-box;
    transition: all 150ms ease-out;
    transition-property: top, opacity;
    /* border-radius: 0.6rem 0 0.6rem 0.6rem; */
    border-radius: 0.6rem;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.Modal_Modal__3oO7n.Modal_Show__tGWZv {
    top: 0;
    opacity: 1;
    pointer-events: all;
}
.Modal_Overlay__2bcFT {
    position: fixed;
    z-index: 1501;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: background 300ms ease-out;
    background-color: transparent;
    pointer-events: none;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.Modal_Overlay__2bcFT.Modal_Show__tGWZv {
    background-color: rgba(0, 0, 0, 0.75);
    pointer-events: all;
}
.Modal_CloseButton__3X1jN {
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    cursor: pointer;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;
}
.Modal_CloseButton__3X1jN.Modal_Show__tGWZv {
    pointer-events: all;
    opacity: 1;
}



@media (min-width: 600px) {
    .Modal_Modal__3oO7n {
        width: 500px;
        max-height: 90vh;
    }
}
